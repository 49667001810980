import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import Link from '../utils/link'
import Seo from '../components/seo'
import Map from '../components/map'

import Instagram from '../assets/images/instagram-blue.svg'
import Facebook from '../assets/images/facebook-blue.svg'
import Twitter from '../assets/images/twitter-blue.svg'
import Pinterest from '../assets/images/pinterest-blue.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  state = {
    name: '',
    email: '',
    model_number: '',
    subject: '',
    phone: '',
    enquiry: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDrop = (files) => {
    let fileList = this.state.files
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return
      fileList.push(files[i].name)
    }
    this.setState({files: fileList})
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  render() {
    let props = {
      ref: "form",
      name: "contact",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    if (this.state.result === "success")
      return (
        <p className="contact__success">
          Thanks for getting in contact with us.
          <br /> A member of our team will be in touch soon.
        </p>
      )

    return (
      <form {...props}>
        <div className="form__row form__row--double">
          <div className='form__col'>
            <input
              type="text"
              name="first_name"
              placeholder="First Name*"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className='form__col'>
            <input
              type="text"
              name="last_name"
              placeholder="Last Name*"
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form__row">
          <input
            type="email"
            name="email"
            placeholder="Your Email*"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row form__row--double">
          <div className='form__col'>
            <input
              type="text"
              name="phone"
              placeholder="Contact number"
              onChange={this.handleChange}
            />
          </div>
          <div className='form__col'>
            <input
              type="text"
              name="model_number"
              placeholder="Model Number (if known)"
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className='form__row'>
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            onChange={this.handleChange}
          />
        </div>
        <div className='form__row'>
          <textarea
            type="text"
            name="enquiry"
            placeholder="Enquiry"
            onChange={this.handleChange}
          />
        </div>
        <div className="form__row form__row--submit">
          <button type="submit" className="btn">
            Submit
          </button>
        </div>
      </form>
    )
  }
}

class ContactUsPage extends Component {

  render() {
    return (
      <>
        <Seo title='Contact Us - Lenoxx' bodyClass='contact-us' description='' />
        <section className='basic'>
          <div className='basic__inner'>
          	<div className='basic__column'>
          		<h1>Contact Us</h1>
              <Form />
          	</div>
          </div>
          <div className='basic__map'>
            <Map />
            <div className='basic__contact'>
              <div className='basic__contact-item'>
                <h4>Address</h4>
                <p>6 Oban Court<br /> 
                Laverton North <br />
                Vic 3026</p>
              </div>
              <div className='basic__contact-item'>
                <h4>Office Hours</h4>
                <p>Monday — Friday<br /> 
                9:00am — 5:00pm</p>
              </div>
              <div className='basic__contact-item'>
                <h4>Contact</h4>
                <p><Link to='tel:+61392672100'>03 9267 2100</Link><br /> 
                <Link to='mailto:lenoxx@lenoxx.com.au'>lenoxx@lenoxx.com.au</Link></p>
                <ul className="footer__social">
                  <li>
                    <a href="https://www.instagram.com/lenoxxelectronics/" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Instagram} alt="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/LenoxxElectronics" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Facebook} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/LenoxxAustralia" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Twitter} alt="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.pinterest.com.au/lenoxx/" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Pinterest} alt="Pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default ContactUsPage
